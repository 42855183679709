<template>
    <div class="modal" v-if="modelValue" :class="{ active: modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Sequence <span>Edit Sequence Settings</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" ref="sequence-setting-form" class="sequence-setting-form">
                <div class="modal_body">
                    <div class="setting_wpr">
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Sequence Name</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.name }">
                                    <Field autocomplete="off" type="text" placeholder="New Member Onboarding 2022" name="name" v-model="form.name" rules="required" label="sequence name" />
                                </div>
                                <ErrorMessage name="name" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Send Email As</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.sender_name }">
                                    <Field autocomplete="off" type="text" placeholder="John Doe" name="sender_name" v-model="form.sender_name" rules="required" label="sender name" />
                                </div>
                                <ErrorMessage name="sender_name" class="text-danger" />
                            </div>
                            <div class="group_item">
                                <label class="input_label">&nbsp;</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.sender_email }">
                                    <Field autocomplete="off" type="text" placeholder="john.doe@gmail.com" name="sender_email" v-model="form.sender_email" rules="required" label="sender email" />
                                </div>
                                <ErrorMessage name="sender_email" class="text-danger" />
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Send Schedule Date</label>
                                <ul class="day_listing">
                                    <li>
                                        <label for="mon" class="checkbox">
                                            <input type="checkbox" id="mon" :true-value="1" :false-value="0" v-model="form.mon" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Mon</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="tue" class="checkbox">
                                            <input type="checkbox" id="tue" :true-value="1" :false-value="0" v-model="form.tue" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Tue</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="wed" class="checkbox">
                                            <input type="checkbox" id="wed" :true-value="1" :false-value="0" v-model="form.wed" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Wed</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="thu" class="checkbox">
                                            <input type="checkbox" id="thu" :true-value="1" :false-value="0" v-model="form.thu" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Thu</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="fri" class="checkbox">
                                            <input type="checkbox" id="fri" :true-value="1" :false-value="0" v-model="form.fri" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Fri</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="sat" class="checkbox">
                                            <input type="checkbox" id="sat" :true-value="1" :false-value="0" v-model="form.sat" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Sat</p>
                                        </label>
                                    </li>
                                    <li>
                                        <label for="sun" class="checkbox">
                                            <input type="checkbox" id="sun" :true-value="1" :false-value="0" v-model="form.sun" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Sun</p>
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Send Message Arround</label>
                                <div class="group-col-3">
                                    <div class="field_wpr">
                                        <multiselect v-model="form.send_hour" v-bind="hours"></multiselect>
                                    </div>
                                    <div class="field_wpr">
                                        <multiselect v-model="form.send_clock" v-bind="clock"></multiselect>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form_grp">
                            <div class="group_item">
                                <label class="input_label">Timezone</label>
                                <div class="field_wpr" :class="{ 'has-error': errors.timezone }">
                                    <Field autocomplete="off" name="timezone" v-model="form.timezone" :class="{ 'has-error': errors.timezone }">
                                        <multiselect
                                          v-model="form.timezone"
                                          :options="timezones"
                                          valueProp="timezone"
                                          label="timezone"
                                          :searchable="true"
                                        >
                                            <template v-slot:option="{ option }">
                                                <span>{{ `${ option.abbr} ${ option.utc_offset_str} ${ option.timezone}` }}</span>
                                            </template>
                                        </multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="timezone" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button class="btn save_btn" v-if="companyUserCan('update', 'sequences')">
                        <i class="fa fa-spin fa-spinner" v-if="sequenceUpdateLoader"></i> {{ sequenceUpdateLoader ? 'Saving' : 'Save' }}
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapGetters, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Sequence Setting',

        data () {
            return {
                form: {
                    name: '',
                    sender_name: '',
                    sender_email: '',
                    mon: 0,
                    tue: 0,
                    wed: 0,
                    thu: 0,
                    fri: 0,
                    sat: 0,
                    sun: 0,
                    send_hour: 7,
                    send_clock: 'AM',
                    timezone: '',
                },
                hours: {
                    mode: 'single',
                    value: [7],
                    options: [
                        { value: 1, label: '01' },
                        { value: 2, label: '02' },
                        { value: 3, label: '03' },
                        { value: 4, label: '04' },
                        { value: 5, label: '05' },
                        { value: 6, label: '06' },
                        { value: 7, label: '07' },
                        { value: 8, label: '08' },
                        { value: 9, label: '09' },
                        { value: 10, label: '10' },
                        { value: 11, label: '11' },
                        { value: 12, label: '12' },
                    ],
                    createTag: true
                },
                clock: {
                    mode: 'single',
                    value: ['AM'],
                    options: [
                        { value: 'AM', label: 'AM' },
                        { value: 'PM', label: 'PM' }
                    ],
                    createTag: true
                },
            };
        },

        props: {
            modelValue: Boolean,
            sequence: Object,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    vm.resetForm();
                }
            },
        },

        mounted () {
            const vm = this;

            if (vm.timezones.length == 0) {
                vm.getTimezones();
            }
        },

        computed: {
            ...mapState({
                timezones: state => state.commonModule.timezones,
                sequenceUpdateLoader: state => state.sequenceModule.sequenceUpdateLoader,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        methods: {
            ...mapActions({
                updateSequence: 'sequenceModule/updateSequence',
                getTimezones: 'commonModule/getTimezones',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    sequence_id: vm.sequence.id,
                    name: vm.sequence.name,
                    sender_name: vm.sequence.sender_name,
                    sender_email: vm.sequence.sender_email,
                    mon: vm.sequence.mon,
                    tue: vm.sequence.tue,
                    wed: vm.sequence.wed,
                    thu: vm.sequence.thu,
                    fri: vm.sequence.fri,
                    sat: vm.sequence.sat,
                    sun: vm.sequence.sun,
                    send_hour: vm.sequence.send_hour,
                    send_clock: vm.sequence.send_clock,
                    timezone: vm.sequence.timezone,
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.setFieldError = setFieldError;

                vm.updateSequence(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        }
    }
</script>

<style scoped>
    .sequence-setting-form {
        display: flex;
        flex-direction: column;
        height: calc(100% - 100px);
    }
    @media(max-width: 767px){
        .sequence-setting-form {
            height: calc(100% - 85px);
        }
    }
</style>
